.help {
  
  border: solid 1px red;
  color: var(--cs-color-text);
  background-color: var(--bs-body-bg);
  border: var(--cs-main-container-border);
  &__h1 {
    color: var(--help-h1-color);
  }

  &__h2 {
    color: var(--help-h2-color);
  }
  
  &__link {
    color: var(--help-link-color);
  }
  
}