.di-chat-login {
  
  h1 {
    font-size: 1.6rem;
  }

  &__form {
    /*min-width: 380px;*/
  }
  
  &__ivalid-credentials {
    height: 1.5rem;
  }
}