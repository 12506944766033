@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.chat-page {
  
  $chat-page-class: &;
  height: 100%;
  &__widget {
    width: 100%;
    height: 100vh;
    /*&--maximized {
      max-width: 100%;
      width: 100%
    }*/
    
    @include media-breakpoint-up(md) {
      /*&:not(#{$chat-page-class}__widget--maximized) {
        max-width: 700px;
        width: 700px;
        padding: 3.3rem 0;
      }*/
    }
    
  }
  
}