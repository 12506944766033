@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";

.wc-header {
  
  &__dynamic-buttons {
    background-color: var(--di-dynamic-button-bg-color);
    left:10px;
    top:11px;
    width: 265px;
    z-index: 1;
  }
  &__btn {
    color: var(--di-wc-header-btn-color) !important;
    border-color: var(--di-wc-header-btn-border-color) !important;
    font-weight: bold;
    &:focus,
    &:hover {
      color: var(--di-a11y-btn-color);
    }
    &:focus-visible {
      outline: var(--di-focus-visible-outline);
    }
    
    &--dynamic.btn:focus-visible {
      background-color: var(--di-dynamic-button-bg-color);
      color: var(--di-dynamic-button-color) !important;
      border-radius:0;
      border:0;
      outline: var(--di-focus-visible-outline);
      width: 100%;
    }
  }
  
  &__contact {
    height:42px;
  }
  
  &__user-name {
    font-size: 1.025em;
  }
}