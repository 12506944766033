.btn.no-messages {
  &__btn {
    color: var(--di-wc-header-btn-color) !important;
    border-color: var(--di-wc-header-btn-border-color) !important;
    font-weight: bold;
    &:focus,
    &:hover {
      color: var(--di-a11y-btn-color);
    }
    &:focus-visible {
      outline: var(--di-focus-visible-outline);
    }
  }
}

.message-input {
  border-top: var(--cs-chat-container-message-input-border-top);
  background-color: var(--cs-main-container-bg-color);
  &__input {
    flex-shrink: initial;
    border-top:0;
    width: calc(100% - 55px);
  }
  
  &__send-btn,
  &__go-to-start-btn{
    margin-left:0.3rem;
    font-size:1.2rem !important;
    width: 48px;
    height:38px;
  }
}

// Żeby input wprowadzzania wiadomości nie wychodził poza kontener
.cs-message-input__content-editor-wrapper {
  &:last-child {
    max-width: fit-content;
    min-width: calc(100% - 15px);
  }
}

// Placeholder przeszkadza w przełaczaniu się na tryb formularza
.cs-message-input__content-editor[data-placeholder]:empty:before {
  content: none !important;
}